
import * as Components from "./Pita"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "pita"
}

